import React, { useState } from 'react'
import useAsyncEffect from 'use-async-effect';
import { getMessages, getTsms } from '../Api';
import { useInterval } from '../hooks';
import { Message, TsmOrder } from './model/Messages';

const Messages = () => {
    
    const [messages, setMessages] = useState<Message[]|undefined>();
    const [tsmOrders, setTsmOrders] = useState<TsmOrder[]|undefined>();

    useAsyncEffect(async (isMounted) => {
        document.title = 'Messages';
        let payload = await getMessages();
        if (isMounted()) {
            setMessages(payload.data.messages);
        }
    }, [])

    useAsyncEffect(async (isMounted) => {
        let payload = await getTsms();
        if (isMounted()) {
            setTsmOrders(payload.data.tsmOrders);
        }
    }, [])
    
    useInterval(async ()=> {
            let payload = await getMessages();
            setMessages(payload.data.messages);
    }, 30000)

    useInterval(async ()=> {
        let payload = await getTsms();
        setTsmOrders(payload.data.tsmOrders);
    }, 30000)

    let messagesDiv = null
    if (messages) {
        messagesDiv = <div id="dataTables">
            <table className="trading-table">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Message</th>
                </tr>
                </thead>
                <tbody>
                {
                    messages.map((row:any, i:number) => <tr key={i}>
                        <td width="20%">{row.dtToSend}</td>
                        <td width="20%">{row.timeToSend}</td>
                        <td width="60%">{row.messageText}</td>                       
                    </tr>)
                }
                </tbody>
            </table>
        </div>
    }


    let tsmDiv = null;
    if (tsmOrders && tsmOrders.length > 0) {
        tsmDiv = <div id="dataTables">
            <table className="trading-table">
                <thead>
                <tr>
                    <th>Tailored Strategy</th>
                    <th>Net Price</th>
                    <th>Total Volume</th>
                    <th>Price</th>
                    <th>Ratio</th>
                    <th>Price</th>
                    <th>Ratio</th>
                    <th>Price</th>
                    <th>Ratio</th>
                    <th>Price</th>
                    <th>Ratio</th>
                    <th>Price</th>
                    <th>Ratio</th>
                    <th>Price</th>
                    <th>Ratio</th>
                    <th>Price</th>
                    <th>Ratio</th>
                    <th>Price</th>
                    <th>Ratio</th>
                </tr>
                </thead>
                <tbody>
                {
                    tsmOrders.map((row:TsmOrder, i:number) => <tr key={i}>
                        <td className="centerCell" width="10%">{row.secCode}</td>
                        <td className="centerCell" width="5%">{row.price}</td>
                        <td className="centerCell" width="5%">{row.value_quantity}</td>
                        <td className="centerCell" width="5%">{row.priceLeg1}</td>
                        <td className="centerCell" width="5%">{row.ratioLeg1}</td>
                        <td className="centerCell" width="5%">{row.priceLeg2}</td>
                        <td className="centerCell" width="5%">{row.ratioLeg2}</td>
                        <td className="centerCell" width="5%">{row.priceLeg3}</td>
                        <td className="centerCell" width="5%">{row.ratioLeg3}</td>
                        <td className="centerCell" width="5%">{row.priceLeg4}</td>
                        <td className="centerCell" width="5%">{row.ratioLeg4}</td>
                        <td className="centerCell" width="5%">{row.priceLeg5}</td>
                        <td className="centerCell" width="5%">{row.ratioLeg5}</td>
                        <td className="centerCell" width="5%">{row.priceLeg6}</td>
                        <td className="centerCell" width="5%">{row.ratioLeg6}</td>
                        <td className="centerCell" width="5%">{row.priceLeg7}</td>
                        <td className="centerCell" width="5%">{row.ratioLeg7}</td>
                        <td className="centerCell" width="5%">{row.priceLeg8}</td>
                        <td className="centerCell" width="5%">{row.ratioLeg8}</td>
                    </tr>)
                }
                </tbody>
            </table>
        </div>
    }
    else {
        tsmDiv = <div style={{marginBottom:"80px"}}>Tailored Strategy Market (TSM) orders whilst active are displayed here</div>
    }
    return <>
    <div>
        <h1>Messages</h1>
        {messagesDiv} 

        <h1>Tailored Strategy Market</h1>
        {tsmDiv}
    </div>
    </>
}



export default Messages