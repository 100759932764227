import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DelayedPricesReport from './components/DelayedPricesReport';
import { createBrowserHistory } from 'history';
import styled from 'styled-components'
import Overview from './components/Overview';
import EoDReport from './components/EoDReport';
import NotFoundPage from './components/NotFoundPage';
import Messages from './components/Messages';
export const history = createBrowserHistory();


const Style = styled.div`
margin: 50px;
`;

function App() {
  return <Style>
  <BrowserRouter>

      <Switch>
          <Route path="/" component={Overview} exact={true} />
          <Route path="/DelayedPricesReport" component={DelayedPricesReport} />          
          <Route path="/EoDReport" component={EoDReport} />  
          <Route path="/Messages" component={Messages} />    
          <Route component={NotFoundPage} />
      </Switch>
  </BrowserRouter>
</Style>
}

export default App;
