import React, {useState, useEffect, useCallback} from 'react';
import useAsyncEffect from 'use-async-effect';
import { getApiUrl, getEodReportDays, getEodReportForDay } from '../Api';
import { useInterval } from '../hooks';
import { Day } from './model/EodResult';
import { Instr } from './model/DelayedPricesResult';

const EoDReport = () => {

    const [instrs, setInstrs] = useState<Instr[]|undefined>();
    const [reportDays, setReportDays] = useState<Day[]>([]);
    const [maxTradeDate, setMaxTradeDate] = useState<Day|undefined>();
    const [selectedDate, setSelectedDate] = useState<Day|undefined>();
    const [selectedInstr, setSelectedInstr] = useState<Instr|undefined>(); 
    
    useInterval(() => {
        refreshDays();
    }, 300000)

    const downloadDayData = useCallback(async () => {
        var result = await getEodReportForDay(selectedDate!.tradeDate);
        setInstrs(result.data.instrs.sort((a:any,b:any) => {
            if (a.sortOrder === b.sortOrder) {
                return a.name.localeCompare(b.name)
            }
            else {
                return (a.sortOrder < b.sortOrder) ? -1 : 1;
            }
        }));
    }, [selectedDate]);


    const refreshDays = async () => {
        let result = await getEodReportDays();
        if (result.data && result.data.days.length) {
            setReportDays(result.data.days);
            if (result.data.days[0].tradeDate !== localStorage['maxTradeDate'] || !maxTradeDate) {
                setMaxTradeDate(result.data.days[0]);                
            }
        }
    }

    useAsyncEffect(async (isMounted) => {
        document.title = 'End of Day Report';
        if (isMounted()) {
            refreshDays();
        }
    }, [])

    // dayData downloaded
    useEffect(()=>{
        if (!instrs || !instrs.length)
            return;

        let savedInstr = localStorage['eodSelectedInstr'];
        let found = false;
        if (savedInstr && savedInstr.length) {
            let saved = instrs.find(x=>x.id===savedInstr);
            if (saved) {
                setSelectedInstr(saved);
                found = true;
            }
        }

        if (!found) {
            if (instrs && instrs.length)
            setSelectedInstr(instrs[0]);
        }

    }, [instrs]); 

    // save maxTradeDate to local storage
    useEffect(() => {
        if (!maxTradeDate) {
            return;
        }

        localStorage.setItem('maxTradeDate', maxTradeDate.tradeDate);
        setSelectedDate(maxTradeDate);
        
    // eslint-disable-next-line
    }, [maxTradeDate])

    // save selectedDate to local storage
    useEffect(()=>{
        if (!selectedDate)
            return;

        localStorage.setItem('selectedDate', selectedDate.tradeDate);
        downloadDayData();
        
    }, [selectedDate, downloadDayData])

    // save selectedInstr to local storage
    useEffect(()=>{
        if (!selectedInstr) {
            return;
        }

        localStorage.setItem('eodSelectedInstr', selectedInstr.id);
    }, [selectedInstr])
    
    const getTotalVolume = () => {
        var totalVolume = 0;
        for (var j = 0; j < selectedInstr!.data.length; j++) {
            if (selectedInstr!.data[j].volume.length > 0) {
                totalVolume += parseInt(selectedInstr!.data[j].volume);
            }
        }
        return totalVolume;
    }

    const getTotalOpenInterest = () => {
        var totalOI = 0;
        for (var j = 0; j < selectedInstr!.data.length; j++) {
            if (selectedInstr!.data[j].openInterest.length > 0) {
                totalOI += parseInt(selectedInstr!.data[j].openInterest);
            }
        }
        return totalOI;
    }

    const getTotalChangeInOpenInterest = () => {
        var totalCIOI = 0;
        for (var j = 0; j < selectedInstr!.data.length; j++) {
            if (selectedInstr!.data[j].changeInOpenInterest.length > 0) {
                totalCIOI += parseInt(selectedInstr!.data[j].changeInOpenInterest);
            }
        }
        return totalCIOI;
    }


    const instrChanged = (event:any) => {
        let selected = event.target.value;
        for (var i = 0; i < instrs!.length; i++) {
            if (instrs![i].id === selected) {
                setSelectedInstr(instrs![i]);
                break;
            }   
        }
    }

    const dateChanged = (event:any) => {
        let selected = event.target.value;
        for (var i = 0; i < reportDays.length; i++) {
            if (reportDays[i].tradeDate === selected) {
                setSelectedDate(reportDays[i]);
                break;
            }   
        }
    }
  

    let sectionSelect = null;
    if (instrs) {
        sectionSelect = <div className="optionsDiv">
            <div className="eodOptions">
                <span>Select - &nbsp; </span>
                <select value={selectedInstr?.id} onChange={instrChanged}>
                    {instrs.map((instr:any)=><option key={instr.id} value={instr.id}>{instr.name}</option>)}
                </select>
                <div className="eodOptions__date">
                    <span>Select Day - </span>
                    <select value={selectedDate?.tradeDate} onChange={dateChanged}>
                    {reportDays!.map((data:any)=><option key={data.tradeDate} value={data.tradeDate}>{data.tradeDateText}</option>)}
                    </select>
                </div>
            </div>
        </div>
    }

    let tableData = null;
    if (selectedInstr) {
        tableData = <>
            <h2>{ selectedInstr.name } {selectedInstr.secondaryFilter} - { selectedDate?.tradeDateText }</h2>
            <br />
            <div id="dataTables">
                    <table className="trading-table">
                        <thead>
                        <tr>
                            <th>Contract</th>
                            <th>Open</th>
                            <th>High</th>
                            <th>Low</th>
                            <th>Last</th>
                            <th>Settle</th>
                            <th>Change</th>
                            <th>Prior Settle</th>
                            <th>Volume</th>
                            <th>Open Interest (OI)</th>
                            <th>Change in OI</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            selectedInstr.data.map((row:any, i:number) => <tr key={i}>
                                <td className="centerCell" width="10%">{row.contractCode}</td>
                                <td className="centerCell" width="9%">{row.open}</td>
                                <td className="centerCell" width="9%">{row.high}</td>
                                <td className="centerCell" width="9%">{row.low}</td>
                                <td className="centerCell" width="9%">{row.last}</td>
                                <td className="centerCell" width="9%">{row.settlement}</td>
                                <td className="centerCell" width="9%">{row.changeInSettlement}</td>
                                <td className="centerCell" width="9%">{row.previousSettlement}</td>
                                <td className="centerCell" width="9%">{(parseInt(row.volume) || 0).toLocaleString() }</td>
                                <td className="centerCell" width="9%">{(parseInt(row.openInterest) || 0).toLocaleString()}</td>
                                <td className="centerCell" width="9%">{(parseInt(row.changeInOpenInterest) || 0).toLocaleString()}</td>
                            </tr>)
                        }
                        <tr className="summary">
                            <td className="centerCell">Total</td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell">{ getTotalVolume().toLocaleString() }</td>
                            <td className="centerCell">{ getTotalOpenInterest().toLocaleString() }</td>
                            <td className="centerCell">{ getTotalChangeInOpenInterest().toLocaleString() }</td>
                        </tr>
                        </tbody>
                        
                        
                    </table>
                </div>
        </>                
    }

    let downloadButtons = null;
    if (selectedInstr) {
        downloadButtons = <>
            <form action={getApiUrl()} method="get">
                <button type="submit">Download Market Summary</button>
                <input type="hidden" id="action" name="action" value="downloadMarketSummary"></input>
                <input type="hidden" id="tradeDate" name="tradeDate" value={selectedDate?.tradeDate}></input>
            </form>
            <br/>
            <form action={getApiUrl()} method="get">
                <button type="submit">Download Off Market Summary</button>
                <input type="hidden" id="action" name="action" value="downloadOffMarketSummary"></input>
                <input type="hidden" id="tradeDate" name="tradeDate" value={selectedDate?.tradeDate}></input>
            </form>
        </>
    }

    return <>
    <h1>End of Day Report</h1>
    {sectionSelect}    
    {downloadButtons}
    {tableData}
    </>
}

export default EoDReport