import React from 'react'
import { Link } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa';

const Overview = () => {
    return <>
    <div>

    <ul>         
         <li><Link to="/DelayedPricesReport">DelayedPricesReport</Link> <FaChevronRight /></li>
         <li><Link to="EoDReport">EoDReport</Link> <FaChevronRight /></li>
         <li><Link to="Messages">Messages</Link> <FaChevronRight /></li>
       </ul>

    </div>
    </>
}

export default Overview