import axios from 'axios';
import { DelayedPricesResult } from './components/model/DelayedPricesResult';

let API_URL: string = "https://data.fexglobal.com.au/api.php";
//let API_URL: string = "https://ite-data.fexglobal.com.au/api.php";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    API_URL = "http://localhost:4000/api.php";
    //API_URL: string = "https://ite-data.fexglobal.com.au/api.php";
    console.log(API_URL);
}

export async function getEodReportDays() : Promise<any> {
    return await axios.get(API_URL + '?action=eodReportDays')
}

export async function getEodReportForDay(day: string) : Promise<any> {
    return await axios.get(API_URL + '?action=eodReport&tradeDate=' + day);
}

export async function getEodReportSingleDayData(tradeDate:string) {
    return await axios.get(API_URL + '?action=eodReport&tradeDate='+tradeDate)
}

export function getApiUrl() : string {
    return API_URL;
}

export async function getDelayedPrices() : Promise<{ data: DelayedPricesResult }> {
    return await axios.get(API_URL + '?action=delayedPrices')
    //return axios.get('/delayed_prices.json')
}

export async function getMessages() : Promise<any> {
    return await axios.get(API_URL + '?action=messages')
}

export async function getTsms() : Promise<any> {
    return await axios.get(API_URL + '?action=tsms')
}