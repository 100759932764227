import React, { useState, useEffect } from 'react'
import useAsyncEffect from 'use-async-effect';
import { Datum, DelayedPricesResult, Instr } from './model/DelayedPricesResult';
import moment from 'moment';
import { useInterval } from '../hooks';
import { getDelayedPrices } from '../Api';

const DelayedPricesReport = () => {

    const [selectedInstr, setSelectedInstr] = useState<Instr|undefined>();
    const [lastUpdated, setLastUpdated] = useState<Date|undefined>();
    const [autoRefresh, setAutoRefresh] = useState<boolean>(true);
    const [delay, setDelay] = useState<number|undefined>();
    const [instrs, setInstrs] = useState<Instr[] | undefined>();

    useAsyncEffect(async (isMounted) => {
        let payload = await getDelayedPrices();
        const autoRefreshItem = localStorage.getItem('autoRefresh');
        if(autoRefreshItem !== null){
            setAutoRefresh(autoRefreshItem === 'true');
        }
        if (isMounted()) {
            setResult(payload.data);

        }

    }, [])

    const setResult = (result:DelayedPricesResult) => {
        setDelay(result.delay);
        setInstrs(result.instrs.sort((a:any,b:any) => {
            if (a.sortOrder === b.sortOrder) {
                return a.name.localeCompare(b.name)
            }
            else {
                return (a.sortOrder < b.sortOrder) ? -1 : 1;
            }
        }))
        setLastUpdated(new Date())
        if(result.instrs.length){
            let savedInstr = localStorage['delayedSelectedInstr'];
            let found = false;
            if (savedInstr && savedInstr.length) {
                let saved = result.instrs.find(x=>x.id===savedInstr);
                if (saved) {
                    setSelectedInstr(saved);
                    found = true;
                }
            }

            if (!found) {
                setSelectedInstr(result.instrs[0]);
            }
        }
    }

    useEffect(() => {
        let delayText = "";
        if (delay) {
            delayText = ' - ' + delay.toString() + ' Min Delay';
        }

        document.title = 'FEX Global Prices' + delayText;
    }, [delay])

    useEffect(()=>{
        if (!selectedInstr) {
            return;
        }

        localStorage.setItem('delayedSelectedInstr', selectedInstr.id);
    }, [selectedInstr])


    useEffect(()=>{
        if (!autoRefresh) {
            return;
        }

        localStorage.setItem('autoRefresh', autoRefresh.toString());
    }, [autoRefresh])

    const instrChanged = (event:any) => {
        setSelectedInstr(instrs!.find(x=>x.id===event.target.value));
    }

    const autoRefreshChanged = (event:any) => {
        //console.log('event.target.checked', event.target.checked)
        localStorage.setItem('autoRefresh', event.target.checked)
        setAutoRefresh(event.target.checked)
    }

    useInterval(async ()=> {
        if(autoRefresh){
            let payload = await getDelayedPrices();
            setResult(payload.data);
        }
    }, 30000)

    let totalVolume = 0;
    if(selectedInstr){
        totalVolume = selectedInstr.data.map(x=>parseInt(x.volume) || 0).reduce((accumulator, currentValue) => accumulator + currentValue)
    }

    return <>
    <div>

        <h1>FEX Global Prices - {delay} Min Delay</h1>

        <div className="delayed-prices-options">

            { instrs &&
                <>
                <span>Select - </span>
                <select className="delayed-prices-options__instr" value={selectedInstr === undefined ? '' : selectedInstr.id} onChange={instrChanged}>
                    {instrs.map((instr:any)=><option key={instr.id} value={instr.id}>{instr.name}</option>)}
                </select>
                <div>
                    <label> <input type="checkbox" checked={autoRefresh} onChange={autoRefreshChanged}/> Auto-Refresh</label>
                </div>
                { lastUpdated && <span className="delayed-prices-options__update">Last Update: {moment(lastUpdated).format('D MMM hh:mm:ss A')}</span>}
                </>
    }

        </div>

        { selectedInstr &&
            <>
            <h2>{selectedInstr.name}</h2>

            <div id="dataTables">
                    <table className="trading-table">
                        <thead>
                        <tr>
                            <th>Contract</th>
                            <th>Bid Volume</th>
                            <th>Bid</th>
                            <th>Ask</th>
                            <th>Ask Volume</th>
                            <th>Last Trade</th>
                            <th>Open</th>
                            <th>High</th>
                            <th>Low</th>
                            <th>Change</th>
                            <th>Daily Volume</th>
                            <th>Prior Settle</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            selectedInstr.data.map((row:Datum, i:number) => <tr key={i}>
                                <td className="centerCell" width="10%">{row.contractCode}</td>
                                <td className="centerCell" width="7.5%">{row.bidVolume}</td>
                                <td className="centerCell" width="7.5%">{row.bid}</td>
                                <td className="centerCell" width="7.5%">{row.ask}</td>
                                <td className="centerCell" width="7.5%">{row.askVolume}</td>
                                <td className="centerCell" width="7.5%">{row.lastTrade}</td>
                                <td className="centerCell" width="7.5%">{row.open}</td>
                                <td className="centerCell" width="7.5%">{row.high}</td>
                                <td className="centerCell" width="7.5%">{row.low}</td>
                                <td className="centerCell" width="7.5%">{row.change}</td>
                                <td className="centerCell" width="7.5%">{(parseInt(row.volume) || 0).toLocaleString() }</td>
                                <td className="centerCell" width="7.5%">{row.previousSettlement}</td>
                            </tr>)
                        }
                        <tr className="summary">
                            <td className="centerCell">Total</td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell"></td>
                            <td className="centerCell">{ totalVolume.toLocaleString() }</td>
                            <td className="centerCell"></td>
                        </tr>
                        </tbody>


                    </table>
                </div>
            </>
        }



    </div>
    </>
}



export default DelayedPricesReport